/* eslint-disable react/display-name */
// @flow
import { useAbility } from '@casl/react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React, {useRef, useState} from 'react';
import { useSelector } from 'react-redux';
import { Icon, Button, HoverCard, HoverCardContent, HoverCardTrigger, Text } from '@shiftfinancial/design-system';
import { InformationIcon, CalendarIcon } from '@shiftfinancial/react-icons/system';

import { useHistory } from 'react-router';

import type { OrderBy } from '../../../api/shiftPaymentsApi';
import { BankLinkDialog, useGetAnalyticsAccountSummary } from '../../../components/BankLinkDialog/';
import startupConfigSelectors from '../../../components/StartupConfig/selectors';

import DataGridPageLayout from '../../../components/DataGridPageLayout';
import DollarsAndCentsText from '../../../components/DollarsAndCentsText';
import InfoTooltip from '../../../components/InfoTooltip';
import { MINIMUM_INVOICE_AMOUNT, Permission, PROBABILITY_OF_DEFAULT_CUTOFF, STANDARD_DATE_FORMAT } from '../../../constants';
import { AbilityContext, Can } from '../../../lib/ability';
import { enrichColumnsWithBodyRenders, getColumnIndex } from '../../../lib/dataGridUtils';
import styles from '../../../theme';
import { InvoicePayoffDialogue } from '../../../components/InvoicePayoffDialog/InvoicePayoffDialogue';

const isEligibleForPayOffLabel = 'isEligibleForPayOff';

const useStyles = makeStyles((theme) => ({
  box: {
    margin: '2% 0',
  },
  noneMarginBottom: { marginTop: '2%', marginBottom: 0 },
  mobileColumnLabel: {
    marginBottom: theme.spacing(0.5),
    fontSize: 12,
  },
  mobileColumnValue: {
    fontWeight: 500,
  },
  mobileRowDataLine: {
    marginBottom: theme.spacing(2),
  },
  mobileRowStatusLine: {
    marginTop: theme.spacing(2),
  },
  companyNameCntainer: {
    overflow: 'hidden',
    width: theme.spacing(21),
  },
  companyName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '20px',
    maxHeight: theme.spacing(7),
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  actionButtonDesktop: {
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(0.5),
  },
  repayTooltipTitle: { fontSize: 14, marginBottom: theme.spacing(1) },
  repayTooltipContent: { fontSize: theme.spacing(1.5) },
  hoverCardMobile: { textAlign: 'center' },
  hoverCard: { 
      paddingLeft: theme.spacing(2), 
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1), 
      paddingBottom: theme.spacing(1), 
  },
  tooltipContainer: {
    padding: theme.spacing(1.5),
  },
  icon: { marginBottom: 2 },
}));

const getColor = (v) => {
  switch (v) {
    case 'Not paid':
      return '#D34343';
    default:
      return styles.palette.common.black;
  }
};

let columns = [
  {
    name: 'id',
    label: ' ',
    options: {
      display: false,
    },
  },
  {
    name: 'supplierAccountName',
    label: 'Company name',
    options: {
      sort: false,
      setCellProps: () => {
        return {
          style: {
            width: '176px',
            paddingTop: '8px',
            paddingBottom: '8px',
          },
        };
      },
    },
  },
  {
    name: 'amount',
    label: 'Invoice $',
    options: {
      sort: true,
      setCellHeaderProps: () => {
        return { align: 'right' };
      },
      setCellProps: () => {
        return {
          align: 'right',
          style: {
            width: '96px',
            paddingTop: '8px',
            paddingBottom: '8px',
          },
        };
      },
    },
  },
  {
    name: 'invoiceReference',
    label: 'Invoice ref.',
    options: {
      sort: true,
      setCellProps: () => {
        return {
          style: {
            width: '108px',
            paddingTop: '8px',
            paddingBottom: '8px',
          },
        };
      },
    },
  },
  {
    name: 'statusText',
    label: 'Supplier paid',
    options: {
      sort: false,
      setCellProps: () => {
        return {
          style: {
            width: '80px',
            paddingTop: '8px',
            paddingBottom: '8px',
          },
        };
      },
    },
  },
  {
    name: 'outstandingAmount',
    label: 'Outstanding $',
    options: {
      sort: true,
      setCellHeaderProps: () => {
        return { align: 'right' };
      },
      setCellProps: () => {
        return {
          align: 'right',
          style: {
            width: '80px',
            paddingTop: '8px',
            paddingBottom: '8px',
          },
        };
      },
    },
  },
  {
    name: 'repaymentAmount',
    label: 'Repayment $',
    options: {
      sort: false,
      setCellHeaderProps: () => {
        return { align: 'right' };
      },
      setCellProps: () => {
        return {
          align: 'right',
          style: {
            width: '96px',
            height: '56px',
            paddingTop: '8px',
            paddingBottom: '8px',
          },
        };
      },
    },
  },
  {
    name: 'debitedOn',
    label: 'Debited on',
    options: {
      sort: false,
      setCellProps: () => {
        return {
          style: {
            width: '96px',
            height: '56px',
            paddingTop: '8px',
            paddingBottom: '8px',
          },
        };
      },
    },
  },
  {
    name: 'extensionStatus',
    label: ' ',
    options: {
      sort: false,
      setCellProps: () => {
        return {
          style: {
            width: '60px',
            padding: '8px',
          },
        };
      },
    },
  },
  {
    name: isEligibleForPayOffLabel,
    label: ' ',
    options: {
      sort: false,
      setCellProps: () => {
        return {
          style: {
            width: '60px',
            padding: '8px',
          },
        };
      },
    },
  },
];

const idIndex = getColumnIndex(columns, 'id');
const statusIndex = getColumnIndex(columns, 'statusText');
const debitedOnIndex = getColumnIndex(columns, 'debitedOn');
const invoiceRefIndex = getColumnIndex(columns, 'invoiceReference');
const amountIndex = getColumnIndex(columns, 'amount');
const outstandingAmountIndex = getColumnIndex(columns, 'outstandingAmount');
const repaymentAmountIndex = getColumnIndex(columns, 'repaymentAmount');
const companyNameIndex = getColumnIndex(columns, 'supplierAccountName');
const extensionStatusIndex = getColumnIndex(columns, 'extensionStatus');
const isEligibleForPayOffLabelIndex = getColumnIndex(columns, isEligibleForPayOffLabel);

type Props = {
  gcAccountId: string,
  isActionBlocked: boolean,
  getInvoices: (gcAccountId: string, skip: number, limit: number, search: ?string, from: ?Date, to: ?Date, orderBy: ?Array<OrderBy>) => Promise<any>,
};

const Invoices = (props: Props) => {
  const { gcAccountId, isActionBlocked, getInvoices } = props;
  const classes = useStyles();
  const history = useHistory();
  const ability = useAbility(AbilityContext);
  const remoteTableRef = useRef(null);

  const [bankLinkEnforcementStatusDialog, setBankLinkEnforcementStatusDialog] = useState(false);
  const analyticsAccountSummary = useGetAnalyticsAccountSummary(gcAccountId);
  const configuration = useSelector(startupConfigSelectors.selectConfiguration);

  const isPayoffEnabled = configuration?.featureConfiguration?.invoicePayoff;
  if (!isPayoffEnabled) {
    columns = columns.filter((e) => e.name !== isEligibleForPayOffLabel);
  }

  const getInvoicesData = (skip: number, limit: number, orderBy: ?Array<OrderBy>) => {
    const from = moment().subtract(12, 'months').toDate();
    const to = moment().toDate();
    
    return getInvoices(gcAccountId, skip, limit, '', from, to, orderBy).then((response) => {
      const { error, payload } = response;
      
      if (!error && payload.data.items) {
          payload.data.items.forEach((i) => {
              i.statusText = i.status === 'Paid' ? moment(i.actualDisbursementDate).format(STANDARD_DATE_FORMAT) : i.status;
          });
      }
      return response;
    });
  };

  const setRefreshInvoicesData = (refresh) => {
    if (refresh) {
      remoteTableRef.current.reloadData();
    }
  };

  const addDashIfBlank = (value, component) => (value ? component : '-');

  const statusLabel = (value) => <Typography style={{ color: getColor(value) }}>{value}</Typography>;

  const extensionComponent = (id, status, isMobile) => {
    switch (status) {
      case 'Extendable':
        return analyticsAccountSummary.probabilityOfDefault < PROBABILITY_OF_DEFAULT_CUTOFF ? (
          <Can I={Permission.Actions.Create} a={Permission.Buyer.Invoice}>
            <Button
              appearance='text'
              className={isMobile ? '' : classes.actionButtonDesktop}
              startIcon={
                !!isMobile && (
                  <Icon className={classes.icon}>
                    <CalendarIcon />
                  </Icon>
                )
              }
              onClick={() => history.push(`/buyer/payments/${id}/extend`)}
              data-testid='uia-extendInvoiceLink'
            >
              Extend
            </Button>
          </Can>
        ) : null;
      case 'ExtensionRequested':
        return (
          <Can I={Permission.Actions.Create} a={Permission.Buyer.Invoice}>
            <InfoTooltip placement='top-end' label='' disabled={false}>
              <Box className={classes.tooltipContainer}>
                <Typography variant='subtitle2'>Extension request in progress</Typography>
                <Typography variant='body2'>You will be notified when the request is processed</Typography>
              </Box>
            </InfoTooltip>
          </Can>
        );
      default:
        return;
    }
  };

  const repayComponent = (isEligibleForPayOff, status, outstandingAmount, id, companyName, invoiceRef, isMobile = false) => {
    if (outstandingAmount >= MINIMUM_INVOICE_AMOUNT && status !== 'Pending') {
      if (isEligibleForPayOff) {
        return (
          <Can I={Permission.Actions.Create} a={Permission.Buyer.Invoice}>
            <InvoicePayoffDialogue
              isMobile={!!isMobile}
              amount={outstandingAmount}
              gcAccountId={gcAccountId}
              contractId={id}
              supplierName={companyName}
              invoiceNumber={invoiceRef}
              setRefreshInvoicesData={setRefreshInvoicesData}
            />
          </Can>
        );
      }
      return (
        <Can I={Permission.Actions.Create} a={Permission.Buyer.Invoice}>
          <Grid className={isMobile ? classes.hoverCardMobile : classes.hoverCard}>
            <HoverCard openDelay={0} data-testid='uia-repay-info-tooltip'>
              <HoverCardTrigger>
                <Icon color='info' size='xsmall' focusable='true' tabIndex={-1} style={{ outline: 'none' }}>
                  <InformationIcon />
                </Icon>
              </HoverCardTrigger>
              <HoverCardContent density='compact' side='top' maxWidth='small'>
                <h3 className={classes.repayTooltipTitle}>Repayment in progress</h3>
                <Text className={classes.repayTooltipContent}>
                  Please allow 3 business days for
                  <br /> the direct debit to be processed
                  <br /> and your Trade account balance
                  <br /> to update.
                </Text>
              </HoverCardContent>
            </HoverCard>
          </Grid>
        </Can>
      );
    }
    return null;
  };

  const desktopColumnCustomBodyRenders = {
    supplierAccountName: (value) => (
      <Box className={classes.companyNameContainer}>
        <Typography nowrap className={classes.companyName}>
          {value}
        </Typography>
      </Box>
    ),
    amount: (value) => <Typography>{addDashIfBlank(value, <DollarsAndCentsText amount={value} />)}</Typography>,
    invoiceReference: (value) => <Typography>{addDashIfBlank(value, value)}</Typography>,
    statusText: (value) => statusLabel(value),
    outstandingAmount: (value) => <Typography>{addDashIfBlank(value, <DollarsAndCentsText amount={value} />)}</Typography>,
    repaymentAmount: (value) => <Typography>{addDashIfBlank(value, <DollarsAndCentsText amount={value} />)}</Typography>,
    debitedOn: (value) => <Typography> {addDashIfBlank(value, moment(value).format(STANDARD_DATE_FORMAT))}</Typography>,
    extensionStatus: (value, { rowData }) => extensionComponent(rowData[idIndex], value),
    isEligibleForPayOff: (value, { rowData }) =>
      repayComponent(
        value,
        rowData[statusIndex],
        rowData[outstandingAmountIndex],
        rowData[idIndex],
        rowData[companyNameIndex],
        rowData[invoiceRefIndex]
      ),
  };

  const mobileRowRender = (rowData) => {
    return (
      <>
        <Grid container className={classes.noneMarginBottom} data-testid='uia-paymentsmobileview'>
          <Grid item xs={12} className={classes.box}>
            <Typography className={classes.mobileColumnLabel}>Company name</Typography>
            <Typography className={classes.mobileColumnValue}>{rowData[companyNameIndex]}</Typography>
          </Grid>
          <Grid item xs={6} className={classes.box}>
            <Typography className={classes.mobileColumnLabel}>Invoice $</Typography>
            <Typography className={classes.mobileColumnValue}>
              <DollarsAndCentsText amount={rowData[amountIndex]} />
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.box}>
            <Typography className={classes.mobileColumnLabel}>Invoice ref.</Typography>
            <Typography className={classes.mobileColumnValue}>{addDashIfBlank(rowData[invoiceRefIndex], rowData[invoiceRefIndex])}</Typography>
          </Grid>
          <Grid item xs={6} className={classes.box}>
            <Typography className={classes.mobileColumnLabel}>Supplier paid</Typography>
            <Typography className={classes.mobileColumnValue} style={{ color: getColor(rowData[statusIndex]) }}>
              {rowData[statusIndex]}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.box}>
            <Typography className={classes.mobileColumnLabel}>Outstanding $</Typography>
            <Typography className={classes.mobileColumnValue}>
              {addDashIfBlank(rowData[outstandingAmountIndex], <DollarsAndCentsText amount={rowData[outstandingAmountIndex]} />)}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.box}>
            <Typography className={classes.mobileColumnLabel}>Repayment $</Typography>
            <Typography className={classes.mobileColumnValue}>
              {addDashIfBlank(rowData[repaymentAmountIndex], <DollarsAndCentsText amount={rowData[repaymentAmountIndex]} />)}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.box}>
            <Typography className={classes.mobileColumnLabel}>Debited on</Typography>
            <Typography className={classes.mobileColumnValue}>
              {addDashIfBlank(rowData[debitedOnIndex], moment(rowData[debitedOnIndex]).format(STANDARD_DATE_FORMAT))}
            </Typography>
          </Grid>
          <Grid item xs={5} className={classes.noneMarginBottom} />
          <Grid item xs={7} className={classes.noneMarginBottom}>
            <Grid container className={classes.box}>
              <Grid item xs={6} className={classes.box}>
                {extensionComponent(rowData[idIndex], rowData[extensionStatusIndex], true)}
              </Grid>
              <Grid item xs={6} className={classes.box}>
                {!!isPayoffEnabled
                  ? repayComponent(
                      rowData[isEligibleForPayOffLabelIndex],
                      rowData[statusIndex],
                      rowData[outstandingAmountIndex],
                      rowData[idIndex],
                      rowData[companyNameIndex],
                      rowData[invoiceRefIndex],
                      true
                    )
                  : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const handleAddInvoice = () => {
    if (analyticsAccountSummary && analyticsAccountSummary.isRequireConnection) {
      setBankLinkEnforcementStatusDialog(true);
      return;
    }
    history.push('/buyer/payments/pay-new-or-existing');
  };

  return (
    <>
      {bankLinkEnforcementStatusDialog ? (
        <BankLinkDialog
          onClose={() => {
            setBankLinkEnforcementStatusDialog(false);
          }}
        />
      ) : null}
      <DataGridPageLayout
        ref={remoteTableRef}
        title='Invoices'
        getData={getInvoicesData}
        columns={enrichColumnsWithBodyRenders(columns, desktopColumnCustomBodyRenders)}
        mobileRowRender={mobileRowRender}
        defaultOrderBy={[{ columnName: 'disbursalDate', isAscending: false }]}
        addButtonOptions={
          ability.can(Permission.Actions.Create, Permission.Buyer.Invoice)
            ? {
                label: 'Pay a supplier',
                onAddClick: handleAddInvoice,
                disabled: isActionBlocked,
                showNextLine: true,
              }
            : null
        }
      />
    </>
  );
};

export default Invoices;
